<template>
  <div class="center">
    <div class="margin-top">
      <h2 class="header" ref="top">{{ $t("RelationshipCalculator") }}</h2>
    </div>
    <div>
      <div class="center__content">
        <p class="description text--medium">
          {{ $t("RelationshipCalculator.Description") }}
        </p>
        <div class="calculator">
          <div class="calculator__options">
            <div class="calculator__option">
              <img
                v-if="firstTypeImg"
                :src="firstTypeImg"
                class="small-image"
                alt="personality type"
              />
              <MyTypeSelect
                :options="personalityTypes"
                @chooseOption="selectType($event, 'me')"
              />
            </div>
            {{ $t("and") }}
            <div class="calculator__option">
              <MyTypeSelect
                :options="personalityTypes"
                @chooseOption="selectType($event, 'friend')"
              />
              <img
                v-if="secondTypeImg"
                :src="secondTypeImg"
                class="small-image"
                alt="personality type"
              />
            </div>
          </div>
          <br />
          <MyTypeButton
            :text='$t("RelationshipCalculator.Calculate")'
            :style="{ margin: 'auto', marginBottom: '2em' }"
            :onClick='calculateRelationship'
          />
        </div>

        <div v-if="result" class="result" ref="result">
          <lottie-animation
            :ref="result.relationType.toLowerCase()"
            :animationData="
              require(`@/assets/animations/${result.relationType.toLowerCase()}.json`)
            "
            :speed="0.7"
            :autoPlay="true"
            class="result-img"
          />
          <h2 ref="result">{{ result.title }}</h2>
          <p class="bold-gray">{{ subtitle }}</p>
          <p v-if="result.subtitle" class="result__text">{{ result.subtitle }}</p>
          <p class="result__text">{{ result.description }}</p>

          <p class="icon">❣️</p>
          <p class="disclaimer">
            <b>{{ $t("RelationshipCalculator.Note.Bold") }}</b>
            {{ $t("RelationshipCalculator.Note") }}
          </p>
          <p class="disclaimer">{{ $t("RelationshipCalculator.Disclamer") }}</p>

          <MyTypeButton
            :text='$t("RelationshipCalculator.Calculate.Another")'
            :style="{ margin: 'auto', marginTop: '4em' }"
            :onClick='scrollToCalculator'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import { useI18n } from 'vue-i18n';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { provideApolloClient, useQuery, useResult } from '@vue/apollo-composable';
import apolloClient from '../apollo';
import { CALCULATE_INTERTYPE_RELATIONSHIP } from '../apollo/mutations';
import { GET_PERSONALITY_TYPES } from '../apollo/queries';
import MyTypeButton from '../components/MyTypeButton.vue';
import MyTypeSelect from '../components/MyTypeSelect.vue';

provideApolloClient(apolloClient);

export default {
  name: 'Calculator',
  data() {
    return {
      firstTypeId: 1,
      secondTypeId: 1,
      firstTypeImg: '/personality_types/entp.svg',
      secondTypeImg: '/personality_types/entp.svg',
      result: null,
    };
  },
  components: { MyTypeButton, MyTypeSelect },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const { result, refetch } = useQuery(GET_PERSONALITY_TYPES);
    const personalityTypes = useResult(result);
    const siteData = reactive({
      title: i18n.t('Meta.RelationshipCalculator.Title'),
      ogtitle: i18n.t('Meta.Calculator'),
      description: i18n.t('RelationshipCalculator.Description'),
    });
    useHead({
      title: computed(() => siteData.title),
      meta: [
        { name: 'description', content: computed(() => siteData.description) },
        { property: 'og:title', content: computed(() => siteData.ogtitle) },
      ],
    });
    store.watch((state) => state.init.locale, () => {
      refetch();
      siteData.title = i18n.t('Meta.RelationshipCalculator.Title');
      siteData.ogtitle = i18n.t('Meta.Calculator');
      siteData.description = i18n.t('RelationshipCalculator.Description');
    });
    return {
      personalityTypes,
    };
  },
  computed: {
    relationImage() {
      if (this.result.relationType === 'REQUEST_RECIPIENT'
      || this.result.relationType === 'REQUESTER') {
        return 'background-image: url("/relations/Request.svg")';
      }
      if (this.result.relationType === 'SUPERVISEE'
      || this.result.relationType === 'SUPERVISOR') {
        return 'background-image: url("/relations/Supervisor.svg")';
      }
      return `background-image: url('/relations/${this.result.relationType.toLowerCase()}.svg')`;
    },
    subtitle() {
      switch (true) {
        case (this.result.rating === 1):
          return this.$t('Compatibility.Highest');
        case this.result.group === 1:
          return this.$t('Compatibility.High');
        case this.result.group === 2:
          return this.$t('Compatibility.Average');
        case this.result.group === 3:
          return this.$t('Compatibility.Below.Average');
        default:
          return this.$t('Compatibility.Low');
      }
    },
  },
  methods: {
    selectType($event, key) {
      this.result = null;
      const selectedTypeId = $event.target.value;
      const selectedType = this.personalityTypes.find((t) => t.id === selectedTypeId);
      const typeImageSrc = `/personality_types/${selectedType.shortTitle.toLowerCase()}.svg`;
      if (key === 'me') {
        this.firstTypeId = selectedTypeId;
        this.firstTypeImg = typeImageSrc;
      } else {
        this.secondTypeId = selectedTypeId;
        this.secondTypeImg = typeImageSrc;
      }
    },
    calculateRelationship() {
      // get data and scroll when retrieved
      this.$apollo.mutate({
        mutation: CALCULATE_INTERTYPE_RELATIONSHIP,
        variables: {
          firstPersonalityType: this.firstTypeId,
          secondPersonalityType: this.secondTypeId,
        },
      })
        .then((response) => {
          this.result = response.data.calculateIntertypeRelation.intertypeRelation;
          setTimeout(() => { this.scrollToElement(); }, 50);
        });
    },
    scrollToElement() {
      const el = this.$refs.result;

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToCalculator() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/index';
@import '@/styles/breakpoints';

.center {
  padding-bottom: 10em;

  .icon {
    font-size: 20px;
  }

  .description {
    margin: auto;
    margin-bottom: 1em;
     @include breakpoint(large) {
      width: 45%;
    }
  }

  .disclaimer {
    text-align: left;

    @include breakpoint(large) {
      text-align: center;
      width: 45%;
    }
  }
}

.calculator {
  margin: auto;
  height: calc(100vh - 200px);

  &__options {
    @include breakpoint(xlarge) {
      display: flex;
      align-items: center;
      margin-top: 2em;
    }
  }

  &__option {

    .small-image {
      display: none;
    }

    @include breakpoint(xlarge) {
      display: flex;
      .small-image {
        height: 100px;
        display: table-caption;
      }
    }
  }

  .select {
    width: 300px;
    margin: 1em 0;

    @include breakpoint(large) {
      margin: 2em 3em;
    }
  }
}

.result {
  font-size: 20px;
  padding: 2em 1em 0 1em;

  h2 {
    text-align: center;
  }

  .result-img {
    height: 200px;
    width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
  }

  &__text {
    margin: auto;
    text-align: left;
    padding-bottom: 1em;
    white-space: break-spaces;

    @include breakpoint(large) {
      width: 50%;
    }
  }
}
</style>
