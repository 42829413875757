<template>
  <div class='center'>
    <h1>Terms and conditions</h1>
    <div class='terms'>
      <h2>Introduction</h2>
      <p>These terms of use govern your use of “Services” provided by Meetch: mobile application
        (“Application”) and meetch.app (“Website”). By using the Services, you agree to these
        terms of use in full and they form part of your contract with us to provide Meetch and
        related services including any available digital content (“Content”). If you disagree
        with these terms of use or any part of these terms of use, please do not register or
        use the Services. Please note that these Terms and Conditions may be amended from time
        to time.
        Notification of any changes will be made by posting new terms onto the website or via email.
        In continuing to use the Services you confirm that you accept the then latest terms and
        conditions in full at the time you use the Services.
      </p>
      <h2>Registration</h2>
      <p>
        You can access the website without registration but if you wish to use the Application,
        you are required to go through the registration process to create your personal “Account”.
        In order to be eligible to register in our Application you must be:
      </p>
      <ul>
        <li>over the age of 18 years old or above;</li>
        <li>
          never convicted of any crime involving violence, and that you are not required to
          register as a sex offender with any state, federal or local sex offender registry;
        </li>
        <li>
          be legally permitted to use the Services by the laws of your home countryto
          use Application.
        </li>
      </ul>
      <p>
        It is not allowed to use another person’s Account or to share your Account with any
        other person without permission. When you register with the Services we will ask you
        for certain personal information. Any personal information that you provide to us will
        be handled in accordance with our Privacy Policy. In order to prevent fraud, you must
        keep your password confidential and must not share it with anyone. If you think someone
        has gained access to your Account, please immediately contact us via info@meetch.app.
        You agree that all personal information that you supply to us will be accurate, complete
        and kept up to date at all times.
        We reserve the right to investigate any possible violations of these Terms, and we may,
        in our sole discretion, immediately terminate any user’s right to use of the App without
        prior notice.
      </p>
      <h2>Personality Test</h2>
      <p>
        Our Services are based on a free personality test (the “Personality Test”). The results of
        the Personality Test are automatically analysed and you will receive a result which will
        assign to you one of sixteen different personality types and one of two personality type
        variants, along with a description of the traits of the particular personality. We may
        also record and use your answers and results for research purposes. Our Content is
        provided for information and entertainment purposes only and for no other purpose. The
        Personality Test alone does not guarantee the correct identification of your personality
        type. Nothing in the Services should be construed as medical, psychological, recruitment,
        business, or any other advice.
      </p>
      <h2>Licence to Use Services</h2>
      <p>
        Unless otherwise stated, we own the intellectual property rights in the Services and
        material on the Services, including all Content, including but not limited to copyright
        and associated moral rights, trade marks, get-up, and unregistered rights, goodwill,
        know-how, software, database rights, and all other intellectual property rights. Subject
        to the licence below, all these intellectual property rights are exclusively reserved. You
        may view, download for caching purposes only, and print pages from the Services for your
        own personal use, subject to the restrictions set out below and elsewhere in these terms
        of use. You agree to:
      </p>
      <ul>
        <li>the services in a professional manner;</li>
        <li>
          submit truthfulinformation upon creation of your account,
          including information submitted from your Facebook account;
        </li>
      </ul>
      <p>You agree not to:</p>
      <ul>
        <li>
          act in an unlawful or unprofessional manner including being dishonest, abusive or
          discriminatory;
        </li>
        <li>reproduce, translate or reverse engineer material from this Services;</li>
        <li>
          use the Service or any content contained in the Services for any commercial purposes
          without our written consent;
        </li>
        <li>edit or otherwise modify any content of the Services;</li>
        <li>
          redistribute material or Content from this Website except for content
          specifically and expressly made available for redistribution;
        </li>
        <li>
          use the Services in any way that could interfere with, disrupt or negatively
          affect the Services;
        </li>
        <li>create fraud or other similar practice and illegal activities.</li>
      </ul>
      <h2>Acceptable Use</h2>
      <p>
        You must not use the  to copy, store, host, transmit, send, use, publish or distribute any
        material which consists of (or is linked to) any malicious computer software. You must not
        conduct any systematic or automated data collection activities (including, without
        limitation, scraping, data mining, data extraction and data harvesting) on or in relation to
        the Services without our express written consent. You must not use the Services to transmit
        or send unsolicited commercial communications. You must not use the Services for any
        purposes related to marketing, excluding Service reviews, without our express written
        consent. You are solely responsible for any content that you publish on the Services or
        communicate to other users. Therefore you agree not to post or communicate:
      </p>
      <ul>
        <li>
          Anything inaccurate, defamatory, abusive, obscene, offensive, profane, sexually oriented,
          threatening, harassing, or racially offensive;
        </li>
        <li>Anything that solicits passwords or other confidential information.</li>
      </ul>
      <p>You agree that you shall not:</p>
      <ul>
        <li>Bully, intimidate, stalk or harass any of the users of the Services;</li>
        <li>
          Do anything to disable or impair the proper working of the Services;
        </li>
        <li>
          Impersonate any other person in any profile whether or not that other person is a user of
          the Services.
        </li>
      </ul>
      <h2>Your Content</h2>
      <p>
        We offer opportunities for users to post content to our Services (“Content”), e.g. in the
        form of Account details or via private messages. We do not have any obligation to store Your
        Content - if it’s important, you should make a copy. Content which does not comply with our
        standards may be removed. By publishing any Content on the Services you confirm that you are
        the author of the Content. Any Content that you publish on the Services will be your
        personal responsibility. You will be personally liable for all claims relating to
        defamation, breach of intellectual property, privacy or any other claim arising from your
        Content. You agree to indemnify us in relation to any liability we may suffer as a result of
        any claims relating to defamation, breach of intellectual property, privacy or any other
        claim arising from your Content. You agree not to publish (or otherwise use the Website to
        distribute) any Content which:
      </p>
      <ul>
        <li>
          violates or infringes anyone’s rights, including rights of publicity, privacy, copyright,
          trademark or other intellectual property or contract right;
        </li>
        <li>displays or promotes pornographic or sexually explicit material of any kind;</li>
        <li>
          does anything or promotes any conduct that is abusive, threatening, obscene, defamatory
          or libellous;
        </li>
        <li>
          is illegal, infringes intellectual property rights, defames any person, breaches
          confidentiality or promotes any illegal activities;
        </li>
        <li>
          promotes illegal or unauthorized copying of another person’s copyright work, such as
          providing pirated computer programs or links to them, providing information to circumvent
          manufacturer-installed copy protect devices, or providing pirated music or links to
          pirated music files;
        </li>
        <li>
          provides instruction information about illegal activities, such as making or buying
          illegal weapons, violating someone else’s privacy or providing or creating computer
          viruses;
        </li>
        <li>involves the transmission of spam or junk emails;</li>
        <li>promotes information that you know to be false or misleading;</li>
        <li>contains personal information e.g. names or contact details; or</li>
        <li>
          engages in commercial activities, such as contests, advertising and pyramid
          schemes.
        </li>
      </ul>
      <p>
        We reserve the right to remove any Content from the Services at any time and for any reason.
      </p>
      <h2>Member Content</h2>
      <p>
        You do not have any rights in relation to other users’ Member Content. If you see Content on
        the Service that violates these Terms, please report the user within the Service or via
        <a href="mailto:info@meetch.app" class="link--red">info@meetch.app</a>.
      </p>
      <h2>Safety</h2>
      <p>
        You agree to use caution in all interactions with other users, particularly if you decide to
        communicate off the Services or meet in person. You agree that you will not provide your
        financial information (for example, your credit card or bank account information), or wire
        or otherwise send money, to other users.
      </p>
      <p>
        YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. YOU UNDERSTAND THAT
        MEETCH DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS OR OTHERWISE INQUIRE INTO
        THE BACKGROUND OF ITS MEMBERS. MEETCH MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE
        CONDUCT OF MEMBERS.
      </p>
      <h2>Third Party Services</h2>
      <p>
        The Services may contain advertisements and promotions offered by third parties and links to
        other web sites or resources. Meetch is not responsible for the availability (or lack of
        availability) of such external websites or resources. If you choose to interact with the
        third parties made available through our Service, such party’s terms will govern their
        relationship with you. Meetch is not responsible or liable for such third parties’ terms or
        actions.
      </p>
      <h2>Modifying the Service and Termination</h2>
      <p>
        You may terminate your account at any time, for any reason via the Service personal profile.
        We reserve the right to terminate your account at any time without notice if we believe you
        violated the Terms.
      </p>
      <h2>Notice and Procedure for Making Claims of Copyright Infringement</h2>
      <p>
        If you believe that your work has been copied and posted on the Service in a way that
        constitutes copyright infringement, please provide us with the following information:
      </p>
      <ul>
        <li>
          A physical or electronic signature of a person authorised to act on behalf of the owner
          of an exclusive right that is allegedly infringed;
        </li>
        <li>
          Identification of the copyrighted work claimed to have been infringed, or, if multiple
          copyrighted works at a single online site are covered by a single notification, a
          representative list of such works;
        </li>
        <li>
          Identification of the material claimed to be infringing or to be the subject of infringing
          activity and that is to be removed or access disabled and information reasonably
          sufficient to permit the service provider to locate the material;
        </li>
        <li>
          Information reasonably sufficient to permit the service provider to contact you, such as
          an address, telephone number, and, if available, an electronic mail;
        </li>
        <li>
          A statement that you have a good faith belief that use of the material in the manner
          complained of is not authorised by the copyright owner, its agent, or the law; and
        </li>
        <li>
          A statement that, under penalty of perjury, the information in the notification is
          accurate and you are authorised to act on behalf of the owner of the exclusive right that
          is allegedly infringed.
        </li>
      </ul>
      <p>
        Notice of claims of copyright infringement should be provided to the us via email to
        <a href="mailto:info@meetch.app" class="link--red">info@meetch.app</a>.
      </p>
      <p>
        Meetch will terminate the accounts of repeat infringers.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL MEETCH, ITS AFFILIATES,
        EMPLOYEES, LICENSORS OR SERVICE PROVIDERS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF
        PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR
        OTHER INTANGIBLE LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS
        OR USE THE SERVICE, (II) THE CONDUCT OR CONTENT OF OTHER MEMBERS OR THIRD PARTIES ON,
        THROUGH, OR FOLLOWING USE OF THE SERVICE; OR (III) UNAUTHORIZED ACCESS, USE OR ALTERATION OF
        YOUR CONTENT, EVEN IF MEETCH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO
        EVENT WILL MEETCH’S AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE SERVICE EXCEED
        THE GREATER OF THE AMOUNT PAID, IF ANY, BY YOU TO MEETCH FOR THE SERVICE AND USD100 WHILE
        YOU HAVE AN ACCOUNT.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR
        ALL OF THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.
      </p>
      <h2>Indemnity</h2>
      <p>
        You hereby indemnify us and undertake to keep us indemnified against any losses, damages,
        costs, liabilities and expenses (including, without limitation, legal expenses and any
        amounts paid by us to a third party in settlement of a claim or dispute on the advice of our
        legal advisers) incurred or suffered by us on a full indemnity basis arising out of any
        breach by you of any provision of these terms of use, or arising out of any claim that you
        have breached any provision of these terms of use.
      </p>
      <h2>Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of Finland. If
        you are located in other country these Terms shall be governed by and construed in
        accordance with the laws of the country where you are located.
      </p>
      <h2>Breaches of These Terms</h2>
      <p>
        Without prejudice to our other rights under these terms of use, if you breach these terms of
        use in any way, we may take such action as we deem appropriate to deal with the breach,
        including suspending your access to the Services, prohibiting you from accessing the
        Services, blocking computers using your IP address from accessing the Services, contacting
        your internet service provider to request that they block your access to the Services and/or
        bringing court proceedings against you.
      </p>
      <h2>Effective date</h2>
      <p>The Terms were last updated on: <b>February 9, 2021</b></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  metaInfo() {
    return {
      title: 'Meetch | Terms and conditions',
      meta: [
        { property: 'description', content: 'Terms and conditions' },
        { property: 'og:title', content: 'Terms and conditions' },
      ],
    };
  },
};
</script>

<style scoped>
  .terms {
    text-align: left;
    padding: 3em;
  }
</style>
