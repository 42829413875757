<template>
  <div class="locale-changer">
    <select v-model="$i18n.locale" class="padding--small" @change="setLocale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.code">
        {{ lang.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'LocaleSwitcher',
  data() {
    return { langs: [{ code: 'en', name: 'English' }, { code: 'ru', name: 'Русский' }] };
  },
  methods: {
    setLocale($event) {
      const lang = $event.target.value;
      this.$i18n.locale = lang;
      localStorage.setItem('lang', lang);
      this.$store.commit('setLocale', lang);
      this.$router.replace({ params: { locale: lang } });
    },
  },
};
</script>

<style lang="scss">
select {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  color: #2c3e50;
  &:hover {
    cursor: pointer;
  }
}

</style>
