<template>
  <div class='bg-red footer'>
    <p>{{ $t('Copyright') }}</p>
    <SocialLinks />
    <div class="legal">
      <router-link to="/terms">
        <p>{{ $t('Terms') }}</p>
      </router-link> |
      <router-link :to="`/${$i18n.locale}/privacy`">
        <p>{{ $t('Privacy') }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import SocialLinks from './SocialLinks.vue';

export default {
  name: 'MeetchFooter',
  components: {
    SocialLinks,
  },
};
</script>

<style lang="scss" scoped>

.footer {
  width: 100%;
  padding: 1em 0 2em 0;

  a {
    flex: 1;
    color: #ffffff;
    text-decoration: none;
  }

  .legal {
    width: 400px;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

  }
}

</style>
