<template>
  <button class="myTypeButton" @click="onClick" :disabled="false" :type="type">
    <slot>{{ text }}</slot>
  </button>
</template>

<script>
export default {
  name: 'MyTypeButton',
  props: {
    type: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../styles/variables";

.myTypeButton {
    background-color: $myTypeRed;
    color: white;
    height: 50px;
    width: 270px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 12px;
    text-transform: uppercase;
    font-size: 16px;
    border: 0;
    display: inline-block;
    box-shadow: $shadow-medium;
    transition: 0.3s;
    outline: none;

    &:hover {
      background-color: white;
      color: $myTypeRed;
      cursor: pointer;
    }
}
</style>
