<template>
  <div class='center'>
    <h2 class='header'>{{ $t('16types') }}</h2>
    <div v-if='loading' class='center'>{{ $t('loading') }}</div>
    <div v-else class='grid cols-2--small personalities'>
      <TypeCard
        v-for='personality in personalityTypes || []'
        :key='personality.title'
        :personalityType='personality'
        class='personality'
      />
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import { useI18n } from 'vue-i18n';
import {
  defineAsyncComponent,
  watchEffect,
  computed,
  reactive,
} from 'vue';
import { provideApolloClient, useQuery, useResult } from '@vue/apollo-composable';
import { useStore } from 'vuex';
import { GET_PERSONALITY_TYPES } from '../apollo/queries';
import apolloClient from '../apollo';

provideApolloClient(apolloClient);

const TypeCard = defineAsyncComponent({
  loader: () => import('../components/TypeCard.vue'),
  delay: 200,
  timeout: 3000,
});

export default {
  name: 'Types',
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const { result, loading, refetch } = useQuery(GET_PERSONALITY_TYPES);
    const personalityTypes = useResult(result);
    watchEffect(() => {
      store.commit('setTypes', personalityTypes.value || []);
    });
    const siteData = reactive({
      title: i18n.t('16types.Page.Title'),
    });
    store.watch((state) => state.init.locale, () => {
      refetch();
      siteData.title = i18n.t('16types.Page.Title');
    });
    useHead({
      title: computed(() => siteData.title),
      meta: [
        { property: 'description', content: 'Learn about each of 16 types of socionics' },
      ],
    });
    return {
      personalityTypes,
      loading,
    };
  },
  components: {
    TypeCard,
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/breakpoints';
@import '@/styles/variables';

.center {
  min-height: 80vh;
}

.personalities {
  min-height: 80vh;
  margin: auto;
  margin-top: 6em;
  margin-bottom: 5em;
  justify-content: center;

  @include breakpoint(large) {
    width: 80%;
  }
}
</style>
