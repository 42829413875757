<template>
  <div class="input-box">
    <p class="label">{{ labelText }}</p>
    <div>
      <input
        :id="id"
        :type="type"
        class="input-field"
        :class="{ 'input-field--error': error }"
        :placeholder="placeholder"
        :value="value"
        :required="required"
        @input="$emit('value', $event.target.value)"
      />
      <p class="input-field--error-text">{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputFieldWithLabel',
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    labelText: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../styles/variables";
@import "@/styles/breakpoints";

.input-box {
  display: block;

  @include breakpoint(medium) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-field {
    border: none;
    font-size: 18px;
    border-bottom: 2px solid #111111;
    padding: 0.8em;
    text-align: center;
    outline: none;

    @include breakpoint(medium) {
      margin-left: 1em;
    }

    .label {
      margin-bottom: 1em;

      @include breakpoint(medium) {
        margin: 0;
      }
    }

    &--error {
      border-color: $myTypeRed;
    }

    &--error-text {
      margin: 0;
      margin-left: 1em;
      padding-top: 0.2em;
      font-size: 11px;
      color: $myTypeRed;
      text-align: center;
    }
  }
}

</style>
