import gql from 'graphql-tag';

export const GET_PERSONALITY_TYPES = gql`
  query personalityTypes {
    personalityTypes {
      id
      title
      shortTitle
      description
      mostCompatibleTypes {
        intertypeRelation {
          title
        }
        personalityType {
          id
          title
          description
          shortTitle
        }
      }
    }
  }
`;

export const GET_INTERTYPE_RELATIONS = gql`
  query intertypeRelations {
    intertypeRelations(excludeAsymmetric: true) {
      relationType
      title
      subtitle
      description
      group
      rating
    }
  }
`;
