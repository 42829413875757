<template>
  <div>
    <YouTube
      :src="src"
      :ref="title"
      :width="330"
      :height="220"
      :vars="{ rel: 0 }"
    />
    <h3><b>{{ title }}</b></h3>
    <p>{{ subtitle }}</p>
  </div>
</template>

<script>
import YouTube from 'vue3-youtube';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'YoutubeVideo',
  components: { YouTube },
  props: ['title', 'src', 'subtitle'],
});
</script>
