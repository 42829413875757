import { createStore } from 'vuex';
import init from './modules/init';
import types from './modules/types';

export default createStore({
  modules: {
    init,
    types,
  },
});
