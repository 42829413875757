<template>
  <div>
    <div
      v-if="!showNav"
      class="hamburger"
      @click="openNav"
    >
      <img src="/menu-sharp.svg" alt="menu" />
    </div>
    <div
      v-if="showNav"
      class="navigation-mobile"
      :class="{ open: showNav }"
      v-click-outside="hideNav"
    >
      <img src="/close-sharp.svg" class="navigation-mobile__close" alt="hide-menu" @click="hideNav" />
      <ul>
        <router-link :to="`/${$i18n.locale}`" @click.native="hideNav">
          <p>{{ $t('home')}}</p>
        </router-link>
        <router-link
          :to="`/${$i18n.locale}/personality-types`"
          @click.native="hideNav"
        ><p>{{ $t('16types')}}</p></router-link>
        <router-link
          :to="`/${$i18n.locale}/relationship-types`"
          @click.native="hideNav"
        ><p>{{ $t('IntertypeRelationships')}}</p></router-link>
        <router-link
          :to="`/${$i18n.locale}/calculator`"
          @click.native="hideNav"
        ><p>{{ $t('Calculator')}}</p></router-link>
        <router-link
          :to="`/${$i18n.locale}/verification`"
          @click.native="hideNav"
        ><p>{{ $t('Verification')}}</p></router-link>
      </ul>
      <LanguageSwitcher class="switcher" />
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher.vue';

export default {
  name: 'NavigationMobile',
  components: {
    LanguageSwitcher,
  },
  data() {
    return {
      showNav: false,
    };
  },
  methods: {
    openNav($event) {
      $event.stopPropagation();
      this.showNav = true;
    },
    hideNav() {
      this.showNav = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.hamburger {
  position: absolute;
  right: 1em;
  top: 1em;
  width: 2em;
  z-index: 2;
  cursor: pointer;
}

.navigation-mobile {
  padding: 1em;
  position: absolute;
  top: 0;
  width: 75%;
  height: 100vh;
  padding: 1em;
  background-color: #fff;
  transition: transform ease 1s;
  box-shadow: -7em 0 10em 0 rgba(#888888, 0.5);
  z-index: 2;

  &__close {
    position: absolute;
    width: 2em;
    right: 2em;
  }

  ul {
    list-style: none;
    line-height: 2em;
    padding-left: 1em;
    text-align: left;

    a {
      font-weight: 400;
      color: $myTypeDarkGray;
      text-decoration: none;

      &.router-link-exact-active {
        font-weight: 500;
        color: $myTypeRed;
      }
    }

    .support {
      width: 55%;
      padding: 0 1em;
      background-color: $myTypeRed;
      color: #ffffff;
    }
  }
}

.open {
  -webkit-animation: slideIn 0.5s forwards;
  -moz-animation: slideIn 0.5s forwards;
  animation: slideIn 0.5s forwards;
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(28%);
  }
}

.switcher {
  float: left;
  margin-left: 0.3em;
}

</style>
