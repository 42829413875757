import { GET_PERSONALITY_TYPES } from '../../apollo/queries';
import apolloClient from '../../apollo';

const initState = {
  personalityTypes: [],
};

const getters = {
  personalityTypes: (s) => s.personalityTypes,
};

const mutations = {
  setTypes: (state, types) => {
    state.personalityTypes = types;
  },
};

const actions = {
  async getTypes({ commit }) {
    apolloClient.query({
      query: GET_PERSONALITY_TYPES,
    })
      .then((res) => {
        commit('setTypes', res.data.personalityTypes);
      })
      .catch((e) => {
        console.warn(e);
      });
  },
};

export default {
  state: initState,
  getters,
  actions,
  mutations,
};
