<template>
  <div>
    <div class='background-header'>
      <h2 class="header">{{ $t('IntertypeRelationships') }}</h2>
    </div>
    <div class='center types'>
      <div v-if='!intertypeRelations' class='center'>Loading...</div>
      <div v-else>
        <div v-for='(group, index) in compatibilityGroups' :key='index'>
          <h2 class='subheader'>{{ group.title }}</h2>
          <div :class='`types__section margin-top`'>
            <RelationshipTypeCard
              v-for='t in group.types'
              :key='t.title'
              :relationshipType='t'
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@vueuse/head';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useQuery, useResult } from '@vue/apollo-composable';
import RelationshipTypeCard from '../components/RelationshipTypeCard.vue';
import { GET_INTERTYPE_RELATIONS } from '../apollo/queries';

export default {
  name: 'Types',
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { result, loading, refetch } = useQuery(GET_INTERTYPE_RELATIONS);
    const relationships = useResult(result);

    useHead({
      title: t('Meta.IntertypeRelationships.Title'),
      meta: [
        { property: 'description', content: t('Meta.IntertypeRelationships.Description') },
        { property: 'og:title', content: t('Meta.IntertypeRelationships.Title') },
      ],
    });

    const intertypeRelations = computed(() => {
      const apiData = relationships.value;
      const list = [];
      if (relationships.value) {
        apiData.forEach((n) => {
          const typeData = n;
          if (typeData.title === 'Supervisor') {
            typeData.title = t('Intertype.Relationships.Page.Supervision');
          }
          if (typeData.title === 'Requester') {
            typeData.title = t('Intertype.Relationships.Page.Request');
          }
          list.push(typeData);
        });
      }
      return list;
    });

    const compatibilityGroups = computed(() => (intertypeRelations.value
      ? [
        {
          title: t('Compatibility.High'),
          types: intertypeRelations.value.filter((r) => r.group === 1),
        },
        {
          title: t('Compatibility.Average'),
          types: intertypeRelations.value.filter((r) => r.group === 2),
        },
        {
          title: t('Compatibility.Below.Average'),
          types: intertypeRelations.value.filter((r) => r.group === 3),
        },
        {
          title: t('Compatibility.Low'),
          types: intertypeRelations.value.filter((r) => r.group === 4),
        },
      ]
      : []));
    store.watch((state) => state.init.locale, () => {
      refetch();
    });
    return {
      compatibilityGroups,
      intertypeRelations,
      loading,
    };
  },
  components: { RelationshipTypeCard },
};
</script>

<style lang="scss" scoped>
@import '@/styles/breakpoints';
@import '@/styles/index';

.types {
  margin: 1em;
  margin-top: 10em;

  &__section {
    margin: 3em 0;
    margin-bottom: 7em;

    @include breakpoint(large) {
      display: flex;
      justify-content: space-between;
    }
  }

  @include breakpoint(xlarge) {
    margin: auto;
    margin-top: 300px;
  }

  @include breakpoint(xxlarge) {
    width: 70%;
  }
}
</style>
