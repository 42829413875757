import { createRouter, createWebHistory } from 'vue-router';
import Calculator from '../views/Calculator.vue';
import Home from '../views/Home.vue';
import PersonalityType from '../views/PersonalityType.vue';
import RelationshipType from '../views/RelationshipType.vue';
import TermsAndConditions from '../views/TermsAndConditions.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import Types from '../views/Types.vue';
import RelationshipTypes from '../views/RelationshipTypes.vue';
import Questionnaire from '../views/Questionnaire.vue';
import Verification from '../views/Verification.vue';
import EmailConfirmation from '../views/EmailConfirmation.vue';
import store from '../store';
import { defaultLocale } from '../i18n';

const routes = [
  {
    path: '/',
    redirect: `/${defaultLocale}`,
  },
  {
    path: '/:locale',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: 'test',
        name: 'Questionnaire',
        component: Questionnaire,
        beforeEnter: (to, from, next) => {
          if (!store.getters.userEmail) {
            next('/');
          }
          next();
        },
        meta: {
          fullPage: true,
        },
      },
      {
        path: 'personality-types',
        name: 'Types',
        component: Types,
      },
      {
        path: 'personality-types/:id',
        name: 'PersonalityType',
        component: PersonalityType,
        props: true,
      },
      {
        path: 'relationship-type/:id',
        name: 'RelationshipType',
        component: RelationshipType,
        props: true,
      },
      {
        path: 'relationship-types',
        name: 'RelationshipTypes',
        component: RelationshipTypes,
      },
      {
        path: 'calculator',
        name: 'Calculator',
        component: Calculator,
      },
      {
        path: 'verification',
        name: 'Verification',
        component: Verification,
      },
      {
        path: 'privacy',
        name: 'Privacy Policy',
        component: PrivacyPolicy,
        props: true,
      },
    ],
  },
  {
    path: '/terms',
    name: 'Terms and conditions',
    component: TermsAndConditions,
    props: true,
  },
  {
    path: '/email-confirmed',
    name: 'Email confirmed',
    component: EmailConfirmation,
    props: {
      success: true,
    },
    meta: {
      fullPage: true,
      noFooter: true,
    },
  },
  {
    path: '/email-confirmation-failed',
    name: 'Email confirmation failed',
    component: EmailConfirmation,
    props: {
      success: false,
    },
    meta: {
      fullPage: true,
      noFooter: true,
    },
  },
];

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});
