<template>
  <div>
    <div class="background-header">
      <h2 class="header">{{ $t("Verification.Title") }}</h2>
    </div>

    <!-- About -->
    <div id="verification-about">
      <div class="grid cols-2">
        <div class="col col__text">
          <h2>{{ $t("Verification.Subtitle") }}</h2>
          <p> {{ $t("Verification.Main") }}</p>
          <h4>{{ $t("Verification.Reasons") }}</h4>
          <ul>
            <li class="perk">{{ $t("Verification.Reason.1") }}</li>
            <li class="perk">{{ $t("Verification.Reason.2") }}</li>
            <li class="perk">{{ $t("Verification.Reason.3") }}</li>
          </ul>
        </div>
        <div class="col col__img center">
          <img src='/brain-with-loop.svg' alt="Brain detection" />
        </div>
      </div>
    </div>

    <!-- Verification types -->
    <div id="verification-options">
      <div class="verification-options">
        <h1>{{ $t("Verification.Pick.Header") }}</h1>
        <p>{{ $t("Verification.Pick.Intro") }}</p>
      </div>
      <div class="options-container">
        <div class="verification-option">
          <h2>{{ $t("Verification.Regular") }}</h2>
          <p class="verification-option__price">59$</p>
          <ul class="verification-option__perks">
            <li class="perk"><p>{{ $t("Verification.Regular.Perk1") }}</p></li>
            <li class="perk"><p>{{ $t("Verification.Regular.Perk2") }}</p></li>
          </ul>
          <h4>{{ $t("Verification.Process") }}</h4>
          <ul class="verification-option__process">
            <li class="perk perk--video">
              <p>{{ $t("Verification.Regular.Process.Step1") }}</p>
            </li>
            <li class="perk perk--verification">
              <p>{{ $t("Verification.Regular.Process.Step2") }}</p>
            </li>
          </ul>
          <MyTypeButton
            :text="$t('Verification.Request')"
            :style="{ width: '100%' }"
            :onClick="() => request($t('Verification.Request.Regular'))"
          />
        </div>
        <div class="verification-option">
          <h2>{{ $t("Verification.Extended") }}</h2>
          <p class="verification-option__price">159$</p>
          <ul class="verification-option__perks">
            <li class="perk">
              <p>{{ $t('Verification.Extended.Perk1') }}
                <a href="https://socioniks.net/en/author/?id=1" class="link--red" target="blank">
                  <b>{{ $t("learnmore") }}</b>
                </a>
              </p></li>
            <li class="perk"><p>{{ $t('Verification.Extended.Perk2') }}</p></li>
            <li class="perk"><p>{{ $t('Verification.Extended.Perk3') }}</p></li>
            <li class="perk"><p>{{ $t('Verification.Extended.Perk4') }}</p></li>
          </ul>
          <h4>{{ $t("Verification.Process") }}</h4>
          <ul class="verification-option__process">
            <li class="perk perk--video"><p>{{ $t("Verification.Extended.Process.Step1") }}</p></li>
            <li class="perk perk--question">
              <p>{{ $t("Verification.Extended.Process.Step2") }}</p>
            </li>
            <li class="perk perk--video">
              <p>{{ $t("Verification.Extended.Process.Step3") }}</p>
            </li>
            <li class="perk perk--verification">
              <p>{{ $t("Verification.Extended.Process.Step4") }}</p>
            </li>
          </ul>
          <MyTypeButton
            :text="$t('Verification.Request')"
            :style="{ width: '100%' }"
            :onClick="() => request($t('Verification.Request.Extended'))"
          />
        </div>
      </div>
      <div>
        {{ $t('Verification.ContactUs') }}
        <a href="mailto:info@meetch.app" class="link link--red">
          <b>info@meetch.app</b>
        </a>
      </div>
    </div>

    <!-- Reviews -->
    <div class="reviews">
      <h1>{{ $t("Verification.Reviews") }}</h1>
      <p class="col__text">{{ $t("Verification.Reviews.Description") }}</p>
      <div class="reviews__videos">
        <YoutubeVideo
          v-for="video in videos"
          :key="video.name"
          class="video"
          :src="video.src"
          :title="video.name"
          :subtitle="video.personality"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watch } from 'vue';
import { useHead } from '@vueuse/head';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import MyTypeButton from '../components/MyTypeButton.vue';
import YoutubeVideo from '../components/YoutubeVideo.vue';

export default {
  name: 'Verification',
  components: {
    MyTypeButton,
    YoutubeVideo,
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const siteData = reactive({
      title: i18n.t('Verification.Page.Title'),
      ogtitle: i18n.t('Meta.Verification.Title'),
      description: i18n.t('Meta.Verification'),
    });
    watch(() => store.getters.locale, () => {
      siteData.title = i18n.t('Verification.Page.Title');
      siteData.ogtitle = i18n.t('Meta.Verification.Title');
      siteData.description = i18n.t('Meta.Verification');
    }, { immediate: true });
    useHead({
      title: computed(() => siteData.title),
      meta: [
        { property: 'description', content: computed(() => siteData.description) },
        { property: 'og:title', content: computed(() => siteData.ogtitle) },
      ],
    });
    const videos = [
      {
        name: 'Haruna',
        personality: 'The Polititian (ESFp)',
        src: 'http://www.youtube.com/watch?v=iXMcOyBzY5M',
      },
      {
        name: 'Jakob',
        personality: 'The Mentor (ENFj)',
        src: 'http://www.youtube.com/watch?v=o3FE6IwDgeg',
      },
      {
        name: 'Nathan',
        personality: 'The Critic (INTp)',
        src: 'http://www.youtube.com/watch?v=VbjchQOPuJ0',
      },
    ];
    return { videos };
  },
  methods: {
    request(mailSubject) {
      const mailTo = 'mailto:info@meetch.app?subject=';
      const mailBody = '&body='.concat(this.$t('Verification.Request.Email'));
      window.location.href = mailTo.concat(mailSubject, mailBody);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/breakpoints';
@import '@/styles/index';

#verification-about {
  margin: 200px 2em;
  margin-bottom: 2em;

  @include breakpoint(medium) {
    margin: 4em 3em;
    margin-top: 300px;
  }

  @include breakpoint(large) {
    margin: 4em 10em;
    margin-top: 300px;
  }

  .col__text {
    padding: 3em 0;
  }

  img {
    width: 80%;
    height: 80%;
  }
}

 h2 {
  line-height: 1em;
}

#verification-options {
  position: relative;
  padding: 2em;
  padding-bottom: 4em;
  background-color: $myTypeLightBlue;

  // add background opacity
  &:after {
    content: "";
    background-image: url('../assets/verification.svg');
    background-attachment: fixed;
    background-position: right 5% top 20em;
    background-repeat: no-repeat;
    background-size: 450px;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.35;
  }

  .verification-option {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    min-height: 350px;
    padding: 2em;
    text-align: left;
    background-color: #ffffff;
    z-index: 1;

    @include breakpoint(large) {
      max-width: 40%;
    }

    @include breakpoint(xlarge) {
      max-width: 30%;
    }

    &__price {
      font-size: 20px;
    }

    &__perks, &__process {
      list-style-type: none;
      padding-left: 1.4em;
      border-bottom: 1px solid $myTypeDarkGray;

      @include breakpoint(large) {
        min-height: 240px;
      }

      .perk {
        list-style-image: url('../assets/done.svg');

        &--question {
          list-style-image: url('../assets/question.svg');
        }
        &--video {
          list-style-image: url('../assets/video.svg');
        }
        &--verification {
          list-style-image: url('../assets/badge-grey.svg');
        }

        p {
          padding-left: 0.5em;
        }
      }
    }

    &__process {
      border-bottom: none;
    }
  }

  @include breakpoint(medium) {
    padding: 4em 3em;
  }

  @include breakpoint(large) {
    padding: 4em 10em;
  }

  .options-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
    margin: 2em 0;
  }
}

.reviews {
  padding: 4em 1em;

  &__videos {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    padding-top: 2em;
    max-width: 100%;

    @include breakpoint(medium) {
      width: 90%;
      margin: auto;
    }

    .video {
      margin: auto;

      &__title {
        margin-top: 0.5em;
      }
    }
  }

  .col__text {
    text-align: center;
  }
}
</style>
