import gql from 'graphql-tag';

export const CALCULATE_INTERTYPE_RELATIONSHIP = gql`
  mutation calculateIntertypeRelation(
    $firstPersonalityType: ID!,
    $secondPersonalityType: ID!
  ) {
    calculateIntertypeRelation(input: {
      firstPersonalityType: $firstPersonalityType,
      secondPersonalityType: $secondPersonalityType
    }) {
      intertypeRelation {
        relationType
        title
        rating
        group
        subtitle
        description
      }
    } 
  }
`;

export const START_QUESTIONNAIRE = gql`
  mutation startQuestionnaire($email: String!) {
    startQuestionnaire(email: $email) {
      questionnaireSubmissionId
      firstQuestionnaireSection {
        id
        firstTrait
        secondTrait
        lines {
          id
          weight
          firstStatement {
            id
            relatedTrait
            text
          }
          secondStatement {
            id
            relatedTrait
            text
          }
        }
      }
    }
  }
`;
