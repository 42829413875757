<template>
  <div class='center'>
    <h1>{{ $t('Privacy') }}</h1>
    <div class='terms'>
      <p>{{ $t('Privacy.P1') }}</p>
      <ul>
        <li>{{ $t('Privacy.Li1') }}</li>
        <li>{{ $t('Privacy.Li2') }}</li>
        <li>{{ $t('Privacy.Li3') }}</li>
        <li>{{ $t('Privacy.Li4') }}</li>
        <li>
          {{ $t('Privacy.Li5') }}
          <a href="mailto:info@meetch.app" class="link--red">info@meetch.app</a>.
        </li>
      </ul>
      <h4>{{ $t('Privacy.CollectedData.Header') }}</h4>
      <p>{{ $t('Privacy.CollectedData.P1') }}
        <a href="mailto:info@meetch.app" class="link--red">info@meetch.app</a>.
      </p>
      <p>{{ $t('Privacy.CollectedData.P2') }}</p>
      <ul>
        <li>{{ $t('Privacy.CollectedData.1') }}</li>
        <li>{{ $t('Privacy.CollectedData.2') }}</li>
        <li>{{ $t('Privacy.CollectedData.3') }}</li>
        <li>{{ $t('Privacy.CollectedData.4') }}</li>
        <li>{{ $t('Privacy.CollectedData.5') }}</li>
        <li>{{ $t('Privacy.CollectedData.6') }}</li>
      </ul>
      <p>{{ $t('Privacy.CollectedData.P3') }}</p>
      <p>{{ $t('Privacy.CollectedData.P4') }}</p>
      <h4>{{ $t('Privacy.DataUsage.Header') }}</h4>
      <p>{{ $t('Privacy.DataUsage.P1') }}</p>
      <ul>
        <li>{{ $t('Privacy.DataUsage.1') }}</li>
        <li>{{ $t('Privacy.DataUsage.2') }}</li>
        <li>{{ $t('Privacy.DataUsage.3') }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  metaInfo() {
    return {
      title: 'Meetch | Privacy Policy',
      meta: [
        { property: 'description', content: 'Privacy Policy' },
        { property: 'og:title', content: 'Privacy Policy' },
      ],
    };
  },
};
</script>

<style scoped>
.terms {
  text-align: left;
  padding: 3em;
}
</style>
