const initState = {
  userEmail: null,
  locale: null,
};

const getters = {
  userEmail: (s) => s.userEmail,
  locale: (s) => s.locale,
};

const mutations = {
  setUserEmail: (state, email) => {
    state.userEmail = email;
  },
  setLocale: (state, lang) => {
    state.locale = lang;
  },
};

export default {
  state: initState,
  getters,
  mutations,
};
