<template>
  <div class="social">
    <a href='https://www.instagram.com/meetchapp/' target="blank">
      <div class='img img__ig' />
    </a>
    <a href='https://www.facebook.com/mytypeapp/' target="blank">
      <div class='img img__fb' />
    </a>
  </div>
</template>

<script>
export default {
  name: 'SocialLinks',
};
</script>

<style lang="scss" scoped>
@import '@/styles/breakpoints';

.social {
  display: flex;
  margin: auto;
  width: 100%;
  justify-content: center;

  @include breakpoint(medium) {
    justify-content: space-around;
    width: 6em;
  }

  .img {
    height: 30px;
    width: 30px;
    margin: 0 0.5em;
    filter: invert(100%);
    background-repeat: no-repeat;

    &__ig {
      background-image: url('../assets/logo-instagram.svg');
    }
    &__fb {
      background-image: url('../assets/logo-facebook.svg');
    }
  }
}
</style>
