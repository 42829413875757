import { createApp } from 'vue/dist/vue.esm-bundler';
import { createHead } from '@vueuse/head';
import { createApolloProvider } from '@vue/apollo-option';
// eslint-disable-next-line
import LottieAnimation from 'lottie-web-vue';
import VueSocialSharing from 'vue-social-sharing';
import apolloClient from './apollo';

import App from './App.vue';
import clickOutside from './utils';
import router from './router';
import store from './store';
import i18n from './i18n';

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
const head = createHead();

const app = createApp(App);
app.use(head);
app.use(store);
app.use(router);
app.use(i18n);
app.use(apolloProvider);
app.use(LottieAnimation);
app.use(VueSocialSharing);

app.directive('click-outside', clickOutside);

app.mount('#app');

export default apolloProvider;
