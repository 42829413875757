<template>
  <div class="select">
    <label>{{ label }}</label>
    <select class='mytypeSelect' @change="chooseOption">
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.id"
      >
        {{ option.title }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'MyTypeSelect',
  props: {
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    chooseOption($event) {
      this.$emit('chooseOption', $event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.select {
  display: inline-grid;

  label {
    font-weight: bold;
    margin-bottom: 0.5em;
  }
}

.mytypeSelect {
  padding: 1em;
  text-align: center;
  border: 1px solid $myTypeRed;
  border-radius: 1.5em;
  font-size: 1em;

  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}
</style>
