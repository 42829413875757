<template>
  <div>
    <router-link :to="`/${$i18n.locale}`"><p>{{ $t('home')}}</p></router-link>
    <router-link :to="`/${$i18n.locale}/personality-types`"><p>{{ $t('16types')}}</p></router-link>
    <router-link :to="`/${$i18n.locale}/relationship-types`">
      <p>{{ $t('IntertypeRelationships')}}</p>
    </router-link>
    <router-link :to="`/${$i18n.locale}/calculator`"><p>{{ $t('Calculator')}}</p></router-link>
    <router-link :to="`/${$i18n.locale}/verification`"><p>{{ $t('Verification')}}</p></router-link>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/variables";

a {
  font-weight: 400;
  color: $myTypeDarkGray;
  text-decoration: none;

  &:hover {
    border-bottom: 2px solid $myTypeRed;
  }

  &.router-link-exact-active {
    font-weight: 500;
    border-bottom: 2px solid $myTypeRed;
  }

  &.support {
    background-color: $myTypeRed;
    color: #ffffff;
    padding: 0 1em;
    cursor: pointer;

    &:hover {
      border-bottom: none;
    }
  }
}

</style>
