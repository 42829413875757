import { createI18n } from 'vue-i18n/index';

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getDefaultLocale() {
  const browserLanguage = navigator.language.split('-')[0];
  if (['en', 'ru'].includes(browserLanguage)) {
    localStorage.setItem('lang', browserLanguage);
    return browserLanguage;
  }
  return 'en';
}

export const defaultLocale = getDefaultLocale();

export default createI18n({
  locale: (localStorage.getItem('lang') || defaultLocale),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});
