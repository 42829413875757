<template>
  <div class="app">
    <router-link :to="`/${$i18n.locale}`">
      <img src="./assets/logo-with-title.svg" alt="Meetch logo" class="logo-title" />
    </router-link>
    <LanguageSwitcher
      v-if="!isMobile && $route.name != 'Questionnaire'"
      class="fixed fixed__top-right"
    />
    <NavigationMobile v-show="isMobile" />
    <Navigation v-if="!isMobile && !isFullPage" id="nav" />
    <router-view />
    <MeetchFooter v-if="isFooterVisible" />
  </div>
</template>

<script>
import { computed, reactive, watch } from 'vue';
import { useHead } from '@vueuse/head';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import MeetchFooter from './components/Footer.vue';
import LanguageSwitcher from './components/LanguageSwitcher.vue';
import Navigation from './components/Navigation.vue';
import NavigationMobile from './components/NavigationMobile.vue';

export default {
  name: 'Meetch',
  components: {
    MeetchFooter,
    LanguageSwitcher,
    Navigation,
    NavigationMobile,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const siteData = reactive({
      siteName: i18n.t('Meta.Site.Name'),
      keywords: i18n.t('Meta.Home.Keywords'),
      title: i18n.t('Meta.Home.Title'),
      description: i18n.t('Meta.Home.Description'),
      lang: i18n.locale,
    });
    useHead({
      title: computed(() => siteData.title),
      htmlAttrs: {
        lang: computed(() => siteData.lang),
      },
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description),
        },
        {
          name: 'keywords',
          content: computed(() => siteData.keywords),
        },
        { property: 'og:title', content: computed(() => siteData.title) },
        { property: 'og:description', content: computed(() => siteData.description) },
        { property: 'og:site_name', content: computed(() => siteData.siteName) },
        { property: 'og:image', content: 'https://meetch.app/brain with loop.svg' },
        { property: 'og:type', content: 'website' },
        { property: 'twitter:title', content: computed(() => siteData.title) },
        { property: 'twitter:image', content: 'https://meetch.app/brain with loop.svg' },
        { property: 'twitter:description', content: computed(() => siteData.description) },
      ],
    });
    watch(() => store.getters.locale, () => {
      siteData.siteName = i18n.t('Meta.Site.Name');
      siteData.keywords = i18n.t('Meta.Home.Keywords');
      siteData.title = i18n.t('Meta.Home.Title');
      siteData.description = i18n.t('Meta.Home.Description');
      siteData.lang = i18n.locale;
    }, { immediate: true });
  },
  watch: {
    $route(to) {
      const lang = to.params.locale;
      if (!['en', 'ru'].includes(lang)) return;
      this.$i18n.locale = lang;
      localStorage.setItem('lang', lang);
      this.$store.commit('setLocale', lang);
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  computed: {
    isFullPage() {
      return this.$route.meta.fullPage;
    },
    isFooterVisible() {
      return !this.$route.meta.noFooter;
    },
    isMobile() {
      return this.windowWidth <= 820;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/breakpoints";
@import "@/styles/index";
@import "@/styles/variables";

body {
  margin: 0;
  overscroll-behavior: none;
}

.app {
  font-family: 'Roboto', -apple-system, 'Segoe UI', 'Open Sans', Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: column wrap;
  min-height: 100vh;
  text-align: center;
  color: #2c3e50;
}

.logo-title {
  position: absolute;
  width: 7em;
  top: 1em;
  left: 1em;
}

#nav {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2em;
  margin: auto;
  justify-content: center;
  padding: 4em 0;
  margin-top: 0;
  font-size: 1em;

  @include breakpoint(medium) {
    padding: 1em;
  }
}
</style>
